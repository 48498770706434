<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ param.headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div>
        <v-form ref="form"
            v-model="valid"
            lazy-validation
    >
      <div v-if="visible.MSG_TIME" class="d-flex align-center">
        <div class="col-3 text-left pa-0">
          <v-badge dot color="#EF9191">지연시간</v-badge>
        </div>
        <div class="col-9 pa-0">
          <v-text-field
              class="form-inp full"
              type="number"
              min="0"
              max="999"
              v-model="paramData.MSG_TIME"
              required
          >
          </v-text-field>
        </div>
      </div>
      <div class="mt-3">
        <v-badge dot color="#EF9191">메시지</v-badge>
        <div class="mt-3">
          <v-textarea
              label=""
              :rules="validateRules.MSG_CONTENT"
              v-model="paramData.MSG_CONTENT"
              no-resize
              required
              hide-details
              outlined
              min="500"
          ></v-textarea>
        </div>
      </div>
    </v-form>
      </div>
      <div class="text-right mt-3">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2" @click="updateMsg">저장</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import {mixin} from "../../mixin/mixin";
export default {
  name: "MENU_M810601P01", //name은 'MENU_' + 파일명 조합
  props: {
    param: {
      type: Object,
    },
  },
  mixins:[mixin],
  data(){
    return {
      valid: true,

      validateRules:{
        MSG_CONTENT: [
          v => !!v || '메시지는 필수입니다.',
          v => v.length <= 200 || 'Max 200 characters',
        ],
      },
      useYnDropItems: [
        { name: "사용", value:"Y" },
        { name: "미사용", value:"N" },
      ],
      visible: {
        MSG_TIME: true,
        MSG_CONTENT: true,
      },
    }
  },
  created(){
    if(this.paramData.MSG_CL === "00") {
      this.visible.MSG_TIME = false;
    }
  },
  methods:{
    validate () {
      return this.$refs.form.validate();
    },
    reset () {
      this.$refs.form.reset();
    },
    restoreXSS_Dec2(strText) {
      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }
      strText = strText.toString();

      //문자열 길이가 4이상일 경우만 처리
      // 이부분이 왜 걸려잇는지 모르겠음...테스트 후 길이체크는 막을 예정..
      // if(strText.length <= 3){return strText;}

      //XSS이 적용되었을 경우 원래 문자로 치환하여 반환한다.
      strText = strText.replaceAll("(", "&#40;");
      strText = strText.replaceAll(")", "&#41;");
      strText = strText.replaceAll("[", "&#91;");
      strText = strText.replaceAll("]", "&#93;");
      //strText = strText.replaceAll("&", "&amp;");
      strText = strText.replaceAll('"', "&quot;");
      strText = strText.replaceAll("'", "&apos;");
      //strText = strText.replaceAll("\r", "<br>");
      strText = strText.replaceAll("\n", "<p>");
      strText = strText.replaceAll("<", "&lt;");
      strText = strText.replaceAll(">", "&gt;");
      return strText;
    },
    resetValidation () {
      this.$refs.form.resetValidation();
    },
    async updateMsg(){
      let requestData = {
        headers: {},
        sendData:{}
      };

      if(this.param.CRUD === "C") {
        // header 세팅
        this.paramData.SNDRCV_CD = "SND";
        requestData.headers["URL"] = "/api/chat/setting/message-manage/regist";
        requestData.headers["SERVICE"] = "chat.setting.message-manage";
        requestData.headers["METHOD"] = "regist";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData = this.paramData;
        requestData.sendData["SNDRCV_CD"] = "SND";
        requestData.sendData["MSG_TYPE"] = "TX";
        requestData.sendData["TEXT_MSG_TIME"] = this.paramData.MSG_TIME;
        let msg = this.restoreXSS_Dec2(this.paramData.MSG_CONTENT);
        requestData.sendData["MSG_CONTENT"] = msg;
        requestData.sendData["TEXT_MSG_CONTENT"] = msg;
        requestData.sendData["USE_YN"] = "Y";

        delete requestData.sendData["TEXT_SYSTEM_MSG_ID"];
        delete requestData.sendData["SYSTEM_MSG_ID"];
        delete requestData.sendData["SYSMSG_TYPE"];
        delete requestData.sendData["strDivID"];

      }else{
        // header 세팅
        requestData.headers["URL"] = "/api/chat/setting/system-mssage-manage/text/modify";
        requestData.headers["SERVICE"] = "chat.setting.system-mssage-manage.text";
        requestData.headers["METHOD"] = "modify";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData = this.paramData;
        if(this.paramData.MSG_CL === "00"){
          requestData.sendData["strDivID"] = "divHelloMsg";
          delete requestData.sendData.TEXT_MSG_TIME;
          delete requestData.sendData.MSG_TIME;
        }else if(this.paramData.MSG_CL === "03"){
          requestData.sendData["strDivID"] = "divHelloMsg";
          requestData.sendData["TEXT_MSG_TIME"] = this.paramData.MSG_TIME;
        }
        let msg = this.restoreXSS_Dec2(this.paramData.MSG_CONTENT);
        requestData.sendData["MSG_CONTENT"] = msg;
        requestData.sendData["TEXT_MSG_CONTENT"] = msg;
      }

      let response = await this.common_postCall(requestData);
      if(!response.HEADER.ERROR_FLAG){
        //"정상적으로 수정되었습니다.";
        if(this.param.CRUD === "C") {
          this.common_alert("정상적으로 등록되었습니다.");
        }else{
          this.common_alert("정상적으로 수정되었습니다.");
        }
        this.$emit('hide');
      }
    },
  },
  computed: {
    paramData(){
      return {
        TEXT_SYSTEM_MSG_ID: this.param.data.SYSTEM_MSG_ID == undefined ? '' : this.param.data.SYSTEM_MSG_ID,
        SYSTEM_MSG_ID: this.param.data.SYSTEM_MSG_ID == undefined ? '' : this.param.data.SYSTEM_MSG_ID,
        SYSMSG_TYPE: this.param.data.MSG_CL == undefined ? '' : this.param.data.MSG_CL,
        strDivID: this.param.data.strDivID == undefined ? '' : this.param.data.strDivID,
        ASP_NEWCUST_KEY: this.param.data.ASP_NEWCUST_KEY == undefined ? '' : this.param.data.ASP_NEWCUST_KEY,
        TEXT_USE_YN: 'Y',
        TEXT_UPD_DEPT_CODE: 'x',
        TEXT_UPD_ID: this.param.userId,
        TEXT_MSG_CONTENT: this.param.data.MSG_CONTENT == undefined ? '' : this.param.data.MSG_CONTENT.replaceAll("<br>", "\n"),
        DEPT_CODE: 'x',
        USER_ID: this.param.userId,
        MSG_CL: this.param.data.MSG_CL == undefined ? '' : this.param.data.MSG_CL,
        MSG_CONTENT: this.param.data.MSG_CONTENT == undefined ? '' : this.param.data.MSG_CONTENT.replaceAll("<br>", "\n"),
        TEXT_MSG_TIME: this.param.data.MSG_TIME == undefined ? '' : this.param.data.MSG_TIME,
        MSG_TIME: this.param.data.MSG_TIME == undefined ? '' : this.param.data.MSG_TIME,
      };
    }
  },
};
</script>

<style></style>
